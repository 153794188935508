import { ActionIcon, Button, Group, Tabs, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { HistoryList } from "../../components/resource/history";
import { RemainderList } from "../../components/resource/remainder";
import { SettingsList } from "../../components/resource/settings";
import { useScreenDetector } from "../../components/screen-detector";

export const ResourceList = () => {
  const ERP_STOCK = usePermission("ERP_STOCK");
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");
  const ERP_STOCK_MVM = usePermission("ERP_STOCK_MVM");

  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [key, setKey] = useState<string>("1");
  const { isMobile } = useScreenDetector();

  const title = [
    {
      key: "1",
      title: "Үлдэгдэл нөөц",
      subTitle: "Бараа бүтээгдэхүүний одоогийн үлдэгдэлийн мэдээлэл",
    },
    {
      key: "2",
      title: "Нөөцийн үлдэгдэл тохиргоо",
      subTitle: "Барааны нөөцийн үлдэгдэлд тохируулга хийх",
    },
    {
      key: "3",
      title: "Нөөцийн түүх",
      subTitle: "Сонгосон барааны нөөцийн түүхийг харах",
    },
  ];

  return (
    <PageLayout
      title={title.find((item: any) => item.key === key)?.title || ""}
      subTitle={title.find((item: any) => item.key === key)?.subTitle || ""}
      breadcrumb={breadcrumbs}
      extra={[
        key === "2" && (
          <Group hidden={!ERP_STOCK_ADJ.isCreate}>
            {!isMobile ? (
              <Button onClick={() => navigate("/resource/new")}>Тооллого бүртгэх</Button>
            ) : (
              <ActionIcon size={36} color="" variant="filled" onClick={() => navigate("/resource/new")}>
                <IconPlus />
              </ActionIcon>
            )}
          </Group>
        ),
      ]}>
      <Tabs
        value={key}
        onChange={(e: string | null) => {
          if (e) setKey(e);
        }}
        defaultValue="1">
        <Tabs.List>
          <Tabs.Tab py="md" value="1">
            <Text fw={500} c={key === "1" ? "violet" : ""}>
              Үлдэгдэл нөөц
            </Text>
          </Tabs.Tab>
          <Tabs.Tab py="md" value="2">
            <Text fw={500} c={key === "2" ? "violet" : ""}>
              Тооллого
            </Text>
          </Tabs.Tab>
          <Tabs.Tab py="md" value="3">
            <Text fw={500} c={key === "3" ? "violet" : ""}>
              Нөөцийн түүх
            </Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1" pt="xs">
          {key === "1" && ERP_STOCK.hasAccess ? <RemainderList /> : ERP_STOCK.accessDenied()}
        </Tabs.Panel>
        <Tabs.Panel value="2" pt="xs">
          {key === "2" && ERP_STOCK_ADJ.hasAccess ? <SettingsList /> : ERP_STOCK_ADJ.accessDenied()}
        </Tabs.Panel>
        <Tabs.Panel value="3" pt="xs">
          {key === "3" && ERP_STOCK_MVM.hasAccess ? <HistoryList /> : ERP_STOCK_MVM.accessDenied()}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/recourse",
    label: "Барааны нөөц",
  },
];
