import { Box, Button, Center, Checkbox, Flex, Modal, Pagination, ScrollArea, Table, Text, TextInput } from "@mantine/core";
import { createStyles } from "@mantine/emotion";

interface CategoryModalProps {
  opened: boolean;
  form: any;
  data: any[];
  filteredData: any[];
  pageSize: number;
  currentPage: number;
  selectedRow: any;
  searchQuery: string;
  handleModalClose: () => void;
  handleFieldChange: (e: any) => void;
  handleCheckBox: (id: string) => void;
  handlePagination: any;
  handleConfirm: () => void;
}

const CategoryModal = (props: CategoryModalProps) => {
  const { opened, data, filteredData, pageSize, currentPage, selectedRow, searchQuery } = props;
  const { handleModalClose, handleFieldChange, handleCheckBox, handlePagination, handleConfirm } = props;
  const { classes } = useStyles();

  return (
    <Modal opened={opened} onClose={handleModalClose} size="90%" centered title="Барааны ангилалын код" padding="lg">
      <Box className={classes.wrapper}>
        <TextInput placeholder="Хайлт хийх" value={searchQuery} onChange={handleFieldChange} mb="sm" w="250px" />
        <ScrollArea className={classes.scrollArea}>
          <Table withTableBorder>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>Код</Table.Th>
                <Table.Th>Нэр</Table.Th>
              </Table.Tr>
            </Table.Thead>
            {data.length > 0 ? (
              <Table.Tbody>
                {data.map((item: any) => (
                  <Table.Tr key={item.id}>
                    <Table.Td width={30}>
                      <Checkbox checked={selectedRow === item.id} onChange={() => handleCheckBox(item.id)} />
                    </Table.Td>
                    <Table.Td width={70}>{item.code}</Table.Td>
                    <Table.Td>{item.name}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            ) : (
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td colSpan={3}>
                    <Center sx={(theme) => ({ padding: theme.spacing.xl })} className={classes.tableEmpty}>
                      <Text>Өгөгдөл олдсонгүй</Text>
                    </Center>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            )}
          </Table>
        </ScrollArea>

        <Flex justify="space-between" align="center" mt="md">
          <Pagination total={Math.ceil(filteredData.length / pageSize)} value={currentPage} onChange={handlePagination} />
          <Button onClick={handleConfirm} disabled={selectedRow === null} ml="auto">
            Болсон
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default CategoryModal;

const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  scrollArea: {
    height: 780,
    [`@media screen and (min-width: 576px) and (max-width: 768px)`]: {
      height: 400,
    },
    [`@media screen and (min-width: 769px) and (max-width: 1024px)`]: {
      height: 500,
    },
    [`@media screen and (min-width: 1025px) and (max-width: 1124px)`]: {
      height: 500,
    },
    [`@media screen and (min-width: 1125px) and (max-width: 1366px)`]: {
      height: 500,
    },
    [`@media screen and (min-width: 1367px) and (max-width: 1440px)`]: {
      height: 500,
    },
    [`@media screen and (min-width: 1441x) and (max-width: 1640px)`]: {
      height: 600,
    },
    [`@media screen and (min-width: 1641px) and (max-width: 1920px)`]: {
      height: 600,
    },
  },
  tableEmpty: {
    minHeight: "61vh",
  },
}));
