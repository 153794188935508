import { AppShell, Box, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";
import { usePermission } from "../permission";
import { useScreenDetector } from "../screen-detector";
import { Breadcrumb } from "./breadcrumb";
import { Header } from "./header";
import { Navigation } from "./navbar";

let __LOAD_WINDOW__ = false;

export function Layout() {
  const { isMobile } = useScreenDetector();
  const [opened, setOpened] = useState<boolean>(false);
  const ERP_DASH = usePermission("ERP_DASH");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" && !ERP_DASH?.hasAccess && !__LOAD_WINDOW__) {
      message.error(ERP_DASH.text);
      if (user?.currentBusiness.type === "BUYER") {
        __LOAD_WINDOW__ = true;
        navigate("/supp");
      }
      if (user?.currentBusiness.type === "SUPPLIER") {
        __LOAD_WINDOW__ = true;
        navigate("/product");
      }
    }
  }, [location, ERP_DASH, navigate, user?.currentBusiness?.type]);

  return (
    <AppShell padding="md" header={{ height: { base: 60, sm: 70, md: 80 } }} navbar={{ width: { lg: 120 }, breakpoint: "lg", collapsed: { mobile: !opened } }}>
      <Header opened={opened} setOpened={setOpened} />
      {opened || !isMobile ? <Navigation opened={opened} setOpened={setOpened} /> : null}
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}

type PageProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  breadcrumb?: { to?: string; label: string }[];
  bgColor?: string;
  loading?: boolean;
};

export function PageLayout({ title, subTitle, breadcrumb, extra, children, loading = false }: PageProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.pageLayout}>
      <LoadingOverlay visible={loading} />
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>
          {(extra || [])?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

type FormProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  bgColor?: string;
  my?: number | string;
};

export function FormLayout({ title, subTitle, bgColor = "white", extra = [], children, my = 0 }: FormProps) {
  const { classes } = useStyles();

  return (
    <Box
      className={classes.formLayout}
      my={my}
      style={{
        backgroundColor: bgColor,
      }}>
      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </Box>
  );
}

type FilterProps = {
  left?: JSX.Element[];
  right?: JSX.Element[];
  bg?: any;
};

export function PageFilter({ left, right, bg }: FilterProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.pageFilter}>
      <div className={classes.sectionElement}>{left}</div>
      <div className={classes.sectionElement}>{right}</div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  pageLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
  formLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: theme.white,
  },
  titleLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  content: {
    display: "block",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    padding: 0,
    color: theme.colors.gray[6],
  },
  extra: {
    display: "flex",
    gap: 15,
  },
  pageFilter: {
    display: "flex",
    justifyContent: "space-between",
  },
  sectionElement: {
    display: "flex",
    gap: 15,
  },
}));
