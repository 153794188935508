import { Avatar, Flex, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { ColumnType, Table } from "../../components/table";
import { currencyFormat } from "../../utils";

export const MostSoldTable = ({ data }: { data: any[] }) => {
  const columns = useHeader();

  return <Table name="most.sold.list" columns={columns} filters={{}} dataSource={data} />;
};

const useHeader = (): ColumnType<any | any>[] => [
  {
    title: "Барааны нэр",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.image}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm">{record?.nameMon}</Text>
            <Text size="sm">
              {record?.variantOptions?.map((item: any, index: number) => {
                return (
                  <Text component="span" key={index}>
                    , {item}
                  </Text>
                );
              })}
            </Text>
          </div>
        </Flex>
      );
    },
  },
  {
    title: "Борлуулсан дүн",
    render: (record) => {
      return (
        <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
          {currencyFormat(record.soldAmount)}
        </Text>
      );
    },
  },
  {
    title: "Борлуулсан тоо",
    render: (record) => <Text size="sm">{record?.soldCount || 0}</Text>,
  },
  {
    title: "Захиалсан бизнес",
    render: (record) => <Text size="sm">{record?.orderedBusinessCount || 0}</Text>,
  },
];
