import { Avatar, Button, Flex, Grid, Group, Text, TextInput } from "@mantine/core";
import { IconChecklist, IconUser } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkApi } from "../../apis";
import { ITypeSetPrice } from "../../apis/network";
import { Form, IFormRef } from "../../components/form";
import { DatePickerField } from "../../components/form/datepicker-field";
import { SelectField } from "../../components/form/select-field";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../models/General";
import { message } from "../../utils/message";
import { renderName } from "../../utils/render-name";
import { SelectFieldQuery } from "./select-field-quey";

const schema = yup.object({
  skuCode: yup.string().required("Заавал бөглөнө!").nullable(),
  priceGroupType: yup.string().required("Заавал бөглөнө!").nullable(),
  priceGroupId: yup.string().required("Заавал бөглөнө!").nullable(),
  priceTierNo: yup
    .number()
    .when("priceGroupType", (priceGroupType, schema) => {
      return typeof priceGroupType === "string" && priceGroupType === "GROUP" ? schema.required("Заавал бөглөнө!").nullable() : schema.notRequired().nullable();
    })
    .optional(),
  priceStartDate: yup.string().required("Заавал бөглөнө!").nullable(),
  priceEndDate: yup.string().required("Заавал бөглөнө!").nullable(),
  businessIds: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

export const NetworkSetPriceForm = ({
  select,
  setSelect,
  onClose,
  action,
  reload,
}: {
  select: any[];
  setSelect: (e: any) => void;
  onClose: () => void;
  action: any[];
  reload: () => void;
}) => {
  const { categories = [], itemTypes = [], tiers = [], itemStatus = [], priceGroupTypes = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const refTable = useRef<ITableRef>(null);
  const refForm = useRef<IFormRef<ITypeSetPrice>>(null);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const now = dayjs().format("YYYY-MM-DD hh:mm");
  const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD hh:mm");

  const [data] = useState<ITypeSetPrice>({
    priceGroupType: null,
    priceGroupId: null,
    priceTierNo: null,
    priceStartDate: now,
    priceEndDate: tomorrow,
    businessIds: select || [],
  });

  const columns = useHeader({
    itemStatus,
    itemTypes,
    categories,
    select,
    setSelect,
    onClick: async (key, record) => {
      switch (key) {
        case "check":
          break;
        case "edit":
          break;
        default:
          break;
      }
    },
  });

  const onSubmit = async (value: any) => {
    setIsloading(true);
    try {
      await NetworkApi.setPrice({
        confirm: value?.confirm || false,
        priceGroupType: value?.priceGroupType,
        priceGroupId: value?.priceGroupId,
        priceTierNo: value.priceGroupType === "SPECIAL" ? 0 : value?.priceTierNo,
        priceStartDate: dayjs(value?.priceStartDate).format(),
        priceEndDate: dayjs(value?.priceEndDate).format(),
        businessIds:
          value?.businessIds.map((item: any) => {
            return item.id;
          }) || [],
      });
      message.success("Үйлдэл амжилттай.");
      setIsloading(false);
      setSelect([]);
      reload();
      onClose();
    } catch (error: any) {
      error?.message && message.error(error?.message);
      console.log(error);
      setIsloading(false);
    }
    setIsloading(false);
  };

  return (
    <Form ref={refForm} initialValues={data} onSubmit={(e) => e} validationSchema={schema}>
      {({ values, errors, setFieldValue }) => {
        return (
          <Grid>
            <Grid.Col>
              <Group justify="space-between">
                <Text fz={"lg"} fw={600}>
                  Тохиргооны мэдээлэл
                </Text>
                <Flex gap="xs" align="center" justify="space-between">
                  <Button
                    onClick={async () => {
                      const isValid = await refForm.current?.submit();
                      if (isValid) {
                        onSubmit({ ...isValid, confirm: true });
                      }
                    }}
                    loading={isLoading}
                    leftSection={<IconChecklist size={"1.2rem"} />}>
                    Батлах
                  </Button>
                </Flex>
              </Group>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <TextInput
                value={`${user?.lastName ? user?.lastName[0] + "." : ""}${user?.firstName || ""}`}
                label="Тохиргоо хийсэн"
                required
                placeholder="UserName, DateTime"
                disabled
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <SelectField
                name="priceGroupType"
                label="Үнийн бүлгийн төрөл"
                placeholder="Стандарт үнийн бүлэг"
                options={(priceGroupTypes ?? []).map((item: any) => {
                  return {
                    label: item?.name,
                    value: item.code,
                  };
                })}
                required
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <SelectFieldQuery values={values} />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <SelectField
                name="priceTierNo"
                disabled={values.priceGroupType === "SPECIAL" || !values.priceGroupType}
                options={(tiers ?? [])
                  .filter((f: any) => f.isSet)
                  .map((item: any) => {
                    return {
                      label: item?.name || `Tier ` + item.tierNo,
                      value: item.tierNo,
                    };
                  })}
                label="Тier сонгох"
                required={values.priceGroupType !== "SPECIAL"}
                placeholder="Тier сонгох"
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <DatePickerField
                name="priceStartDate"
                label="Үнэ эхлэх огноо"
                placeholder="Огноо оруулах"
                onChange={() => {
                  setFieldValue("priceEndDate", undefined);
                }}
                showTime
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <DatePickerField showTime disabled={!values?.priceStartDate} name="priceEndDate" label="Үнэ дуусах огноо" placeholder="Огноо оруулах" />
            </Grid.Col>
            <Grid.Col>
              <Text fz={"lg"} fw={600} my={"sm"}>
                Тохиргоонд хамаарах харилцагчид
              </Text>
              <Table minWidth={"max-content"} ref={refTable} name="product.list.asdss" columns={columns} dataSource={select ?? []} pagination={false} />
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
};

const useHeader = ({
  onClick,
  itemStatus,
  itemTypes,
  categories,
  select,
  setSelect,
}: {
  onClick: (key: string, record: IItem | any) => void;
  itemStatus: IReference[];
  itemTypes: any[];
  categories: any[];
  select: any[];
  setSelect: (e: any) => void;
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Партнер нэр",
    dataIndex: "image",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.partner?.businessName || "-"}
        </Text>
        <Text size="sm" c="violet" w="max-content">
          #{record?.partner?.refCode || "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => `${record?.regNumber || "-"}`,
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.profileName || "-"}
        </Text>
        <Text size="sm" w="max-content" c="violet">
          #{record?.refCode || "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Менежер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return record.coBusinessStaff ? (
        <Flex align="center" gap={10}>
          <Avatar src={record.coBusinessStaff?.avatar} />
          <div>
            <Text tt={"capitalize"} size="sm">
              {renderName(record?.coBusinessStaff)}
            </Text>
            <Text size="sm" c="violet">
              {record?.coBusinessStaff?.email || "-"}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Санхүү",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      if (record?.coBusinessFinStaffs?.length === 1)
        return (
          <Flex align="center" gap={10}>
            <Avatar src={record.coBusinessFinStaffs[0]?.avatar} />
            <div>
              <Text tt={"capitalize"} size="sm">
                {renderName(record?.coBusinessFinStaffs[0])}
              </Text>
              <Text size="sm" c="violet">
                {record?.coBusinessFinStaffs[0]?.email || "-"}
              </Text>
            </div>
          </Flex>
        );
      return (
        <Flex align="center" gap={10}>
          <IconUser />
          <Text tt={"capitalize"} size="sm">
            {renderName(record?.coBusinessFinStaffs[0])}
          </Text>
        </Flex>
      );
    },
  },
];
