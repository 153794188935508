import { Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export function PopoverText({
  children,
  withArrow = true,
  position = "top",
}: {
  children: string;
  withArrow?: boolean;
  position?: "top" | "left" | "bottom" | "right";
}) {
  const [opened, { close, open }] = useDisclosure(false);
  if (children.length > 18) {
    return (
      <Popover position={position} withArrow={withArrow} shadow="sm" opened={opened}>
        <Popover.Target>
          <Text component="span" w="max-content" size="sm" style={{ cursor: "pointer" }} onMouseEnter={open} onMouseLeave={close}>
            {children.substring(0, 18)}...
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">{children}</Text>
        </Popover.Dropdown>
      </Popover>
    );
  }

  return <Text size="sm">{children}</Text>;
}
