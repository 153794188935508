import { Avatar, Box, Divider, Flex, Group, Paper, Text } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconUser } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { StockAdjustmentApi, WarehouseApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { dateTimeFormat } from "../../utils/date";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { SelectFilter } from "../select-filter";
import { Tag } from "../tags";

export const SettingsList = () => {
  const { adjustmentStatus, adjustmentTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters, setFilters] = useState<{ warehouseId: string }>({ warehouseId: "" });
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");

  const [warehouseFilterDelay, setWarehouseFilterDelay] = useDebouncedState<{ query: string }>({ query: "" }, 800);
  const columns = useHeader({ adjustmentStatus, adjustmentTypes });

  const { data: warehouseSelects = [] } = useSwr<any[]>(
    ERP_STOCK_ADJ.isEdit && `/erp/warehouse/select?query=${JSON.stringify(warehouseFilterDelay)}`,
    async () =>
      await WarehouseApi.select({
        query: warehouseFilterDelay.query || "",
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  return (
    <>
      <Paper p={"sm"} bg={"gray.0"}>
        <Group justify="apart" hidden={!ERP_STOCK_ADJ.isEdit}>
          <Box w={"calc(100% - 105px)"} maw={"320px"}>
            <SelectFilter
              iconWidth={120}
              onSearchChange={(e) => {
                setWarehouseFilterDelay({
                  ...warehouseFilterDelay,
                  query: e,
                });
              }}
              label="Агуулах"
              value={`${filters?.warehouseId || ""}`}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  warehouseId: e,
                });
              }}
              data={
                warehouseSelects?.map((item: any) => {
                  return {
                    name: item?.name || "",
                    code: item?.id || "",
                  };
                }) || []
              }
            />
          </Box>
        </Group>
      </Paper>
      <Divider mt={"xs"} />
      {ERP_STOCK_ADJ.isView ? (
        <Table name="stock.adjustment.list" columns={columns} filters={filters} loadData={(data) => StockAdjustmentApi.list(data!)} />
      ) : (
        ERP_STOCK_ADJ.accessDenied()
      )}
    </>
  );
};

const useHeader = ({ adjustmentStatus, adjustmentTypes }: { adjustmentStatus: IReference[]; adjustmentTypes: any[] }): ColumnType<any>[] => [
  {
    title: "Огноо цаг",
    sorter: true,
    dataIndex: "item.image",
    render: (record) => <Text size="sm">{dateTimeFormat(record.createdAt)}</Text>,
  },
  {
    title: "Гүйлгээ №",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => <Text size="sm">{record?.refCode ?? "-"}</Text>,
  },
  {
    title: "Хэрэглэгч",
    sorter: true,
    dataIndex: "regUser",
    render: (record) => (
      <Flex align="center" gap={10}>
        <Avatar src={record?.regUser?.avatar}>
          <IconUser />
        </Avatar>
        <Text tt="capitalize" size="sm">
          {record?.regUser?.lastName ? record?.regUser?.lastName[0] + "." : ""}
          {record?.regUser?.firstName ?? "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => <Text size="sm">{adjustmentTypes.find((f: any) => f.code === record.type)?.nameMN ?? "-"}</Text>,
  },
  {
    title: "Агуулахын нэр",
    sorter: true,
    dataIndex: "warehouseId",
    render: (record) =>
      record?.warehouse ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.warehouse.name}
          </Text>
          <Text c="dimmed" size="sm" w="max-content">
            {record?.warehouse.refCode}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "adjustmentStatus",
    render: (record) =>
      Tag(
        adjustmentStatus.find((item: any) => item.code === record.adjustmentStatus)?.color || "blue",
        adjustmentStatus.find((item: any) => item.code === record.adjustmentStatus)?.name || record.adjustmentStatus,
      ),
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record.description}</PopoverText>,
  },
  {
    title: "Reference №",
    sorter: true,
    dataIndex: "reference",
    render: (record) => <Text size="sm">{record.reference ?? "-"}</Text>,
  },
];
