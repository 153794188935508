import { Grid, Input, LoadingOverlay } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import React, { RefObject, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { WarehouseApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { dateTimeFormat } from "../../utils/date";
import { Form, IFormRef } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { usePermission } from "../permission";
import { PackageAddForm } from "./package-add-form";
import { ResourceProductList } from "./product";

const schema = yup.object({
  warehouseId: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().required("Заавал бөглөнө!").nullable(),
  reference: yup.string().required("Заавал бөглөнө!").nullable(),
  variants: yup
    .array()
    .of(
      yup.object().shape({
        variantId: yup.string().required("Заавал бөглөнө!").nullable(),
        movementType: yup.string().required("Заавал бөглөнө!").nullable(),
        quantity: yup.number().min(1, "Заавал бөглөнө!").max(10000, "Хэт их байна!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
        adjustmentNoteId: yup.string().required("Заавал бөглөнө!").nullable(),
        availableQuantity: yup.number().nullable().optional(),
      }),
    )
    .min(1, "emty")
    .required("emty"),
  confirm: yup.boolean(),
});

type IFormData = {
  warehouseId: string | undefined;
  description: string | undefined;
  reference: string | undefined;
  variants: {
    variantId: string | undefined;
    movementType: string | undefined;
    quantity: number;
    adjustmentNoteId: string | undefined;
    availableQuantity: number;
  }[];
  confirm: boolean;
};

export function RecourceForm({ formRef }: { formRef: RefObject<IFormRef<any>> }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");

  const [warehouseFilterDelay, setWarehouseFilterDelay] = useDebouncedState<any>({ query: "" }, 800);

  const { data: warehouseSelects = [] } = useSwr<any[]>(
    ERP_STOCK_ADJ.isEdit && `/erp/warehouse/select?query=${JSON.stringify(warehouseFilterDelay)}`,
    async () =>
      await WarehouseApi.select({
        query: warehouseFilterDelay.query || "",
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [data] = React.useState<IFormData>({
    warehouseId: "",
    description: undefined,
    reference: undefined,
    variants: [],
    confirm: false,
  });

  return (
    <div>
      <Form ref={formRef} validationSchema={schema} onSubmit={(data) => {}} initialValues={data}>
        {({ setFieldValue, values, errors }) => {
          return (
            <div>
              <LoadingOverlay visible={loading} />

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Гүйлгээний №" required>
                    <Input placeholder="Гүйлгээний №" readOnly value={"авто үүснэ"} />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Тохиргоо хийсэн" required>
                    <Input value={dateTimeFormat(`${new Date()}`)} placeholder="Тохиргоо хийсэн" readOnly />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Тохиргоо хийж буй" required>
                    <Input value={`${user?.lastName ? user?.lastName[0] + ". " : ""}${user?.firstName}`} placeholder="Тохиргоо хийж буй" readOnly />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    onSearchChange={(e: string) => {
                      setWarehouseFilterDelay(e);
                    }}
                    name="warehouseId"
                    label="Агуулах сонгох"
                    placeholder="Агуулах сонгох"
                    disabled={values?.variants?.length > 0}
                    onChange={() => {}}
                    options={warehouseSelects.map((i: any) => {
                      return {
                        label: i.name,
                        value: i.id,
                      };
                    })}
                    searchable
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="description" label="Тайлбар" placeholder="Тайлбар" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="reference" label="Тооллогын дугаар" placeholder="Тооллогын дугаар" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 12 }}>
                  <PackageAddForm setFieldValue={setFieldValue} values={values} />
                  <ResourceProductList errors={errors} setFieldValue={setFieldValue} values={values} />
                </Grid.Col>
              </Grid>
            </div>
          );
        }}
      </Form>
    </div>
  );
}
