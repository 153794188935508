import {
  ActionIcon,
  Alert,
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Paper,
  Table,
  Text,
} from "@mantine/core";
import { randomId, useDebouncedValue } from "@mantine/hooks";
import { IconCalendarTime, IconCheck, IconDeviceFloppy, IconId, IconMail, IconPhone, IconPhoto, IconRowInsertBottom, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { GoodsWarehouseApi } from "../../apis";
import { IGoodsWarehouse } from "../../apis/goods-werehouse";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { updateManagers } from "../../store/general";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { NumberField } from "../form/number-field";
import { SelectFetchField } from "../form/select-fetch-field";
import { SelectField } from "../form/select-field";
import { FormLayout } from "../layout";
import { PopoverText } from "../popover-text/popover-text";

const schema = yup.object().shape({
  warehouseId: yup.string().required("Заавал бөглөнө!").nullable(),
  quantityStartDate: yup.date().required("Заавал бөглөнө!").nullable(),
  warehouseQuantity: yup.number().moreThan(0, "0 -с их утга оруулна уу!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  variantId: yup.string().required("Заавал бөглөнө!").nullable(),
  confirm: yup.boolean().optional(),
  minQuantity: yup
    .number()
    .min(0, "Заавал бөглөнө!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .test("", "", function (value, test) {
      if (value && value > (test?.parent?.noticeMinQuantity ?? 0)) {
        return this.createError({ message: `Анхааруулга өгөх минимум үлдэгдэлээс бага байх!` });
      }
      return true;
    }),
  noticeMaxQuantity: yup
    .number()
    .min(0, "Хамгийн багадаа 0 -с их!")
    .typeError("Зөвхөн тоон утга оруулна уу!")
    .required("Заавал бөглөнө!")
    .test("is-greater-than-min", "Анхааруулга өгөх минимум үлдэгдэлээс их байх!", function (value) {
      const { noticeMinQuantity } = this.parent;
      return value == null || value > (noticeMinQuantity ?? 0);
    }),
  noticeMinQuantity: yup
    .number()
    .min(1, "Хамгийн багадаа 1 -с их!")
    .typeError("Зөвхөн тоон утга оруулна уу!")
    .required("Заавал бөглөнө!")
    .test("is-less-than-max", "Анхааруулга өгөх максимум үлдэгдэлээс бага байх!", function (value) {
      const { noticeMaxQuantity } = this.parent;
      return value == null || value < (noticeMaxQuantity ?? Infinity);
    }),
  deactivateMinQuantity: yup
    .number()
    .min(0, "Заавал бөглөнө!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .test("is-greater-than-minQuantity", "Захиалгын нөөцөд заавал байлгах үлдэгдэлээс бага байх!", function (value) {
      const { minQuantity } = this.parent;
      if (minQuantity && value && value > minQuantity) {
        return false;
      }
      return true;
    }),
  minOrderQuantity: yup.number().min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").optional(),
  reOrderQuantityLevel: yup
    .number()
    .min(0, "Заавал бөглөнө!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .optional()
    .test("", "", function (value, test) {
      if (value && value < (test?.parent?.deactivateMinQuantity || 0)) {
        return this.createError({ message: `Жагсаалтад идэвхгүй болгох үлдэгдэлээс их байх!` });
      }
      return true;
    })
    .test("", "", function (value, test) {
      if (value && value < (test?.parent?.minQuantity ?? 0)) {
        return this.createError({ message: `Захиалгын нөөцөд заавал байлгах үлдэгдэлээс их байх!` });
      }
      return true;
    }),
  reOrderQuantity: yup
    .number()
    .min(0, "Заавал бөглөнө!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .optional()
    .test("", "", function (value, test) {
      if (value && value < (test?.parent?.minOrderQuantity ?? 0)) {
        return this.createError({ message: `Минимум захиалгын тооноос их байх!` });
      }
      return true;
    }),
  merchStaffId: yup.string().required("Заавал бөглөнө!").nullable(),
  variantSuppliers: yup
    .array()
    .when("supplierType", {
      is: "RESELLER",
      then: (schema) =>
        schema
          .of(
            yup.object().shape({
              merchStaffId: yup.string().required("Заавал бөглөнө!").nullable(),
              buyerBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
              supplierBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
              isDefault: yup.boolean().optional(),
              isDropshipping: yup.boolean().optional(),
              backorderable: yup.boolean().optional(),
              partnerId: yup.string().required("Заавал бөглөнө!").nullable(),
            }),
          )
          .min(1, "Заавал бөглөнө!")
          .required("Заавал бөглөнө!"),
    })
    .optional(),
});

type ITyprWarehouseUser = {
  id: string | undefined;
  avatar: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  email: string | undefined;
  phone: number | undefined;
};

type ITypeWarehouseSelect = {
  id: string;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  deletedAt?: string | undefined;
  partnerId: string | undefined;
  warehouseUserId: string | undefined;
  regUserId: string | undefined;
  refCode: string | undefined;
  name: string;
  warehouseStatus: string | undefined;
  district: string | undefined;
  phone: string | undefined;
  isDefault: boolean;
  isBuyer: boolean;
  isSupplier: boolean;
  warehouseUser: ITyprWarehouseUser;
};

type ITypeStaff = {
  id: string | undefined;
  avatar: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
};

export function WherehouseSetForm({ onClose, data, reload }: { onClose: () => void; data: any; reload: () => void }) {
  const refForm = useRef<
    IFormRef<{
      submit: () => any;
      validate: () => Promise<{ [key: string]: string }>;
      setFormData: (callback: (state: any) => any) => void;
    }>
  >(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { supplierTypes = [], managers } = useSelector((state: { general: IGeneral }) => state.general);
  const [warehouseQuery, setWarehouseQuery] = useState<{ query: string; limit?: number }>({ query: "" });
  const [merchStaffQuery, setMerchStaffQuery] = useState<{ query: string; limit?: number }>({ query: "" });

  const [warehouseUser, setWarehouseUser] = useState<ITyprWarehouseUser>({
    avatar: "",
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    phone: 0,
  });

  const [merchStaff, setMerchStaff] = useState<ITypeStaff>();
  const [warehouseQueryDelay] = useDebouncedValue(warehouseQuery, 800);
  const [merchStaffQueryDelay] = useDebouncedValue(merchStaffQuery, 800);

  const dispatch = useDispatch();

  const { data: merchStaffList } = useSwr<any>(
    `/erp/goods/warehouse/merch_staff?${JSON.stringify(merchStaffQueryDelay)}`,
    async () => await GoodsWarehouseApi.merchStaff(merchStaffQueryDelay),
    {
      fallbackData: [
        {
          id: undefined,
          avatar: undefined,
          lastName: undefined,
          firstName: undefined,
          email: undefined,
          phone: undefined,
        },
      ],
    },
  );

  const { data: warehouseList } = useSwr<any>(
    `/erp/goods/warehouse/select?${JSON.stringify(warehouseQueryDelay)}`,
    async () => await GoodsWarehouseApi.select(warehouseQueryDelay),
    {
      fallbackData: [
        {
          id: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          deletedAt: null,
          partnerId: undefined,
          warehouseUserId: undefined,
          regUserId: undefined,
          refCode: undefined,
          name: undefined,
          warehouseStatus: undefined,
          district: undefined,
          phone: undefined,
          isDefault: false,
          isBuyer: false,
          isSupplier: true,
          warehouseUser: {
            id: undefined,
            avatar: undefined,
            lastName: null,
            firstName: undefined,
            email: undefined,
            phone: undefined,
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (data?.warehouse) {
      setWarehouseUser(data?.warehouse?.warehouseUser);
    }
    if (data?.merchStaff) {
      setMerchStaff(data?.merchStaff || {});
    }
  }, [data]);

  useEffect(() => {}, [managers]);

  const onSubmit = async (values: IGoodsWarehouse | any) => {
    setIsLoading(true);
    try {
      if (values.supplierType === "PRODUCER") {
        await GoodsWarehouseApi.warehouse({
          ...values,
          noticeMaxQuantity: values?.noticeMaxQuantity || null,
          deactivateMinQuantity: values?.deactivateMinQuantity || null,
          minOrderQuantity: values?.minOrderQuantity || null,
          reOrderQuantityLevel: values?.reOrderQuantityLevel || null,
          reOrderQuantity: values.reOrderQuantity || null,
          quantityStartDate: values?.quantityStartDate || null,
          variantSuppliers: [],
          supplierType: undefined,
        });
        reload();
        message.success("Хүсэлт амжилттай.");
        setIsLoading(false);
        onClose();
      } else if (values.supplierType === "RESELLER") {
        if (values.variantSuppliers.some((item: any) => item?.isDefault || false)) {
          await GoodsWarehouseApi.warehouse({
            ...values,
            noticeMaxQuantity: values?.noticeMaxQuantity || null,
            deactivateMinQuantity: values?.deactivateMinQuantity || null,
            minOrderQuantity: values?.minOrderQuantity || null,
            reOrderQuantityLevel: values?.reOrderQuantityLevel || null,
            reOrderQuantity: values?.reOrderQuantity || null,
            quantityStartDate: values?.quantityStartDate || null,
            variantSuppliers:
              values.variantSuppliers?.map((item: any) => {
                return {
                  buyerBusinessId: item?.buyerBusinessId,
                  supplierBusinessId: item?.supplierBusinessId,
                  merchStaffId: item?.merchStaffId,
                  isDefault: item?.isDefault || false,
                  isDropshipping: item?.isDropshipping || false,
                  backorderable: item?.backorderable || false,
                };
              }) || [],
            supplierType: undefined,
          });

          reload();
          message.success("Хүсэлт амжилттай.");
          setIsLoading(false);
          onClose();
        } else message.error("Нийлүүлэгчийн мэдээлэллийг бүрэн бөглөнө үү.", "Default 1 утга сонгоно уу!");
      }
    } catch (error: any) {
      message.error("Хүсэлт амжилтгүй!");
    }
    setIsLoading(false);
  };

  function renderManager(id: string | null) {
    const find = managers?.find((f: any) => f.value === id)?.item?.merchStaff;
    if (find) {
      return (
        <Flex gap={"xs"} align="center">
          <Avatar src={find.avatar} size={28} />
          <div>
            <Text tt={"capitalize"}>
              {find.lastName ? find.lastName[0] + "." : ""}
              {find.firstName}
            </Text>
          </div>
        </Flex>
      );
    } else return "-";
  }

  useEffect(() => {
    if (data?.variantSuppliers?.length > 0) {
      dispatch(
        updateManagers([
          ...data?.variantSuppliers?.map((item: any) => {
            return {
              label: item?.supplierBusiness?.profileName,
              value: item?.supplierBusiness?.id,
              item: { ...item?.supplierBusiness, merchStaff: item?.merchStaff },
            };
          }),
        ]),
      );
    }
  }, [dispatch, data]);

  if (!data) return <></>;
  else
    return (
      <Paper>
        <Form
          ref={refForm}
          validationSchema={schema}
          initialValues={{
            variantId: data?.id,
            confirm: false,
            warehouseId: data?.warehouseId || undefined,
            quantityStartDate: data?.quantityStartDate !== null ? data?.quantityStartDate : dayjs(new Date()).format(),
            warehouseQuantity: data?.warehouseQuantity || undefined,
            minQuantity: data?.minQuantity || undefined,
            noticeMinQuantity: data?.noticeMinQuantity || undefined,
            noticeMaxQuantity: data?.noticeMaxQuantity || undefined,
            deactivateMinQuantity: data?.deactivateMinQuantity || undefined,
            minOrderQuantity: data?.minOrderQuantity || undefined,
            reOrderQuantityLevel: data?.reOrderQuantityLevel || undefined,
            reOrderQuantity: data?.reOrderQuantity || undefined,
            merchStaffId: data?.merchStaffId || undefined,
            supplierType: data?.supplierType || undefined,
            variantSuppliers: (data?.variantSuppliers ?? []).map((item: any) => {
              return {
                id: item?.id ?? undefined,
                buyerBusinessId: item?.buyerBusinessId ?? undefined,
                supplierBusinessId: item?.supplierBusinessId ?? undefined,
                merchStaffId: item?.merchStaffId ?? undefined,
                isDefault: item?.isDefault ?? undefined,
                isDropshipping: item?.isDropshipping ?? undefined,
                partnerId: item?.supplierBusiness?.partner?.id ?? undefined,
                backorderable: item?.backorderable ?? false,
              };
            }),
          }}
          onSubmit={(e) => e}>
          {({ errors, values, setFieldValue }) => {
            return (
              <Box p={"lg"}>
                <LoadingOverlay visible={isLoading} />
                <FormLayout title={"АГУУЛАХ, ҮЛДЭГДЭЛ ТОХИРГОО"} extra={[<CloseButton onClick={() => onClose()} key={9} />]}>
                  <Divider />
                  <Box>
                    <Group my={"md"} justify="space-between" align="center">
                      <Text fw={600} fz={"lg"}>
                        Ерөнхий мэдээлэл
                      </Text>
                      <Flex gap={"md"}>
                        {!data.isWarehouseSet && (
                          <Button
                            onClick={async () => {
                              let valid = await refForm.current?.submit();
                              if (!valid) {
                                return message.error("Талбарууд алдаатай байна!");
                              } else {
                                onSubmit({ ...values, confirm: false });
                              }
                            }}
                            leftSection={<IconDeviceFloppy size={"1.2rem"} />}
                            variant="outline">
                            Хадгалах
                          </Button>
                        )}
                        {!data.isWarehouseSet && (
                          <Button
                            onClick={async () => {
                              let valid = await refForm.current?.submit();
                              if (!valid) {
                                return message.error("Талбарууд алдаатай байна!");
                              } else {
                                onSubmit({ ...values, confirm: true });
                              }
                            }}
                            type="submit"
                            leftSection={<IconCheck size={"1.2rem"} />}
                            variant="filled">
                            Батлах
                          </Button>
                        )}
                      </Flex>
                    </Group>
                    <Grid>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                        <Flex gap={12}>
                          <Paper withBorder>
                            <Avatar size={160} src={data?.image} radius="xs">
                              <IconPhoto />
                            </Avatar>
                          </Paper>
                          <Flex direction={"column"} gap={"sm"}>
                            <Flex gap={8} style={{ flex: 1 }}>
                              <Text c="gray.8" fw={600} size="sm">
                                SKU:
                              </Text>
                              <Text size="sm">{data?.skuCode || "-"}</Text>
                            </Flex>
                            <Flex gap={8} wrap="wrap" style={{ flex: 1 }}>
                              <Text c="gray.8" fw={600} size="sm">
                                Barcode:
                              </Text>
                              <PopoverText>{data?.barCode || "-"}</PopoverText>
                            </Flex>
                            <Flex gap={8} style={{ flex: 1 }}>
                              <Text c="gray.8" fw={600} size="sm">
                                DeHUB Code:
                              </Text>
                              <Text size="sm">{data?.refCode || "-"}</Text>
                            </Flex>
                            <Flex gap={8} style={{ flex: 1 }}>
                              <Text c="gray.8" fw={600} size="sm">
                                Нэр:
                              </Text>
                              <Text size="sm">{data?.nameMon || "-"}</Text>
                            </Flex>
                            <Flex gap={8} style={{ flex: 1 }}>
                              <Text c="gray.8" fw={600} size="sm">
                                Үнэ тохируулах тоо:
                              </Text>
                              <Text size="sm">{data?.baseUnit?.convertValue || "-"}</Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, md: 8 }}>
                        <Flex direction="column" gap={14}>
                          <Input.Wrapper label="Захиалга бэлтгэх төрөл:" required>
                            <Group py={"sm"}>
                              {supplierTypes?.map((item: IReference, index: number) => {
                                return <Checkbox disabled key={index} checked={data.supplierType === item.code} readOnly value={item.code} label={item.name} />;
                              })}
                            </Group>
                            <SelectField
                              disabled={data.isWarehouseSet}
                              searchValue={warehouseQuery.query}
                              searchable
                              clearable
                              options={
                                warehouseList?.map((item: ITypeWarehouseSelect) => {
                                  return {
                                    label: item?.name || "",
                                    value: item?.id || "",
                                  };
                                }) || []
                              }
                              onSearchChange={(e: any) => {
                                setWarehouseQuery({ query: e });
                              }}
                              placeholder="Сонгоно уу"
                              icon={
                                <Text c="violet" fz={"sm"}>
                                  Агуулах нэр :
                                </Text>
                              }
                              iconWidth={120}
                              name="warehouseId"
                              onChange={(e: string | null) => {
                                setWarehouseUser(warehouseList?.find((item: ITyprWarehouseUser) => item.id === e)?.warehouseUser || {});
                              }}
                            />
                          </Input.Wrapper>
                          <Input.Wrapper label="Агуулах хариуцсан нярав:" required>
                            <Text fz={"sm"} fw={500}>
                              {warehouseUser?.lastName && warehouseUser?.lastName[0] + "."}
                              {warehouseUser?.firstName || "-"}
                            </Text>
                          </Input.Wrapper>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col>
                        <Text fz={"lg"} fw={600}>
                          Эхний үлдэгдэл
                        </Text>

                        <Flex gap="md">
                          <DatePickerField
                            disabled={data.isWarehouseSet}
                            name="quantityStartDate"
                            label={"Огноо:"}
                            icon={<IconCalendarTime size={18} color="gray" />}
                            placeholder="Огноо сонгох"
                            clearable
                            showTime
                            styles={{ wrapper: { width: `220px` } }}
                          />
                          <NumberField disabled={data.isWarehouseSet} label="Үлдэгдэл:" placeholder="Оруулах" name="warehouseQuantity" />
                        </Flex>
                      </Grid.Col>
                      <Grid.Col>
                        <Text fz={"lg"} fw={600} mb={"sm"}>
                          Захиалгын нөөц хянах мэдээлэл
                        </Text>
                        <Flex direction={"column"}>
                          {stock?.map((item: any, index: number) => {
                            return (
                              <Box key={index} h={"60px"}>
                                <NumberField
                                  name={`${[item.name]}`}
                                  disabled={data.isWarehouseSet}
                                  placeholder={item.placeholder}
                                  icon={
                                    <Paper
                                      style={{
                                        borderColor: Object.keys(errors).some((som) => som === item.name) ? "red" : "",
                                      }}
                                      w={"100%"}
                                      fz={"sm"}
                                      px={"lg"}
                                      color="black"
                                      bg={"gray.0"}
                                      h={"36px"}
                                      p={"6px"}
                                      withBorder
                                      mr={"md"}>
                                      <Group align="right">
                                        {item.required && <Text c="red">*</Text>} {item.label} :
                                      </Group>
                                    </Paper>
                                  }
                                  iconWidth={400}
                                />
                              </Box>
                            );
                          })}
                        </Flex>
                      </Grid.Col>
                      <Grid.Col>
                        <Text fz={"lg"} fw={600} mb={"sm"}>
                          Мерчандайзинг хариуцсан
                        </Text>
                        <div>
                          <SelectField
                            disabled={data.isWarehouseSet}
                            searchValue={merchStaffQuery?.query}
                            searchable
                            clearable
                            options={(merchStaffList ?? []).map((item: ITypeStaff) => {
                              return {
                                label: item?.firstName || "",
                                value: item?.id || "",
                              };
                            })}
                            onSearchChange={(e: any) => {
                              setMerchStaffQuery({ query: e });
                            }}
                            placeholder="Сонгоно уу"
                            icon={
                              <Text c="violet" fz={"sm"}>
                                Менежер нэр :
                              </Text>
                            }
                            iconWidth={140}
                            onChange={(e: string | null) => {
                              setMerchStaff(merchStaffList?.find((ml: any) => ml.id === e) || {});
                              setFieldValue(`merchStaffId`, e);
                            }}
                            name="merchStaffId"
                          />
                        </div>
                        <Paper mt={"lg"} withBorder w={"max-content"} p={"sm"} miw={410} mih={91}>
                          {merchStaff?.firstName ? (
                            <Flex align={"center"} gap={"sm"} h={"100%"}>
                              <>
                                {<Avatar src={merchStaff?.avatar} size={"lg"} />}
                                <div>
                                  <Text size={"sm"} fw={"bold"} tt={"capitalize"}>
                                    {merchStaff?.lastName && merchStaff?.lastName[0] + "."}
                                    {merchStaff?.firstName && merchStaff?.firstName}
                                  </Text>
                                  {merchStaff?.email && (
                                    <Flex gap={"4px"} align={"center"}>
                                      <IconMail size={"16px"} /> <Text size={"sm"}>{merchStaff?.email}</Text>
                                    </Flex>
                                  )}
                                  {merchStaff?.phone && (
                                    <Flex gap={"4px"} align={"center"}>
                                      <IconPhone size={"16px"} />
                                      <Text size={"sm"}>{merchStaff?.phone}</Text>
                                    </Flex>
                                  )}
                                </div>
                              </>
                            </Flex>
                          ) : (
                            <Center w={"100%"} h={"59px"}>
                              <IconId color="gray" />
                            </Center>
                          )}
                        </Paper>
                      </Grid.Col>
                      {data?.supplierType === "RESELLER" && (
                        <Grid.Col>
                          <Group justify="apart">
                            <Text fz={"lg"} fw={600} py={"md"}>
                              Нийлүүлэгчийн мэдээлэл
                            </Text>
                            <Button
                              hidden={data?.isWarehouseSet}
                              onClick={() => {
                                setFieldValue(`variantSuppliers`, [
                                  ...(values?.variantSuppliers || []),
                                  {
                                    id: randomId(),
                                    buyerBusinessId: undefined,
                                    supplierBusinessId: undefined,
                                    partnerId: undefined,
                                    merchStaffId: undefined,
                                    isDefault: !values?.variantSuppliers?.some((item2: any) => item2?.isDefault || false),
                                    isDropshipping: false,
                                    backorderable: false,
                                  },
                                ]);
                              }}
                              leftSection={<IconRowInsertBottom size={20} />}
                              size="xs"
                              variant="gradient">
                              Мөр нэмэх
                            </Button>
                          </Group>
                          <Box style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }} pb={"lg"}>
                            <Table miw={"max-content"} w={"100%"}>
                              <Table.Thead>
                                <Table.Tr>
                                  <Table.Th hidden={data?.isWarehouseSet}>Үйлдэл</Table.Th>
                                  <Table.Th>Default</Table.Th>
                                  <Table.Th>Манай Buyer бизнес </Table.Th>
                                  <Table.Th>Нийлүүлэгч Партнер </Table.Th>
                                  <Table.Th>Нийлүүлэгч бизнес</Table.Th>
                                  <Table.Th>Менежер</Table.Th>
                                  <Table.Th>Drop ship хийх эсэх</Table.Th>
                                  <Table.Th>Backorder хийх эсэх</Table.Th>
                                </Table.Tr>
                              </Table.Thead>
                              <Table.Tbody>
                                {(values?.variantSuppliers?.length || 0) > 0 ? (
                                  values?.variantSuppliers?.map((item: any, index: number) => {
                                    return (
                                      <Table.Tr key={item.id ?? index}>
                                        <Table.Td hidden={data?.isWarehouseSet}>
                                          <ActionIcon
                                            onClick={() => {
                                              setFieldValue("variantSuppliers", values?.variantSuppliers?.filter((item2: any) => item !== item2) || []);
                                            }}
                                            color="red"
                                            variant="light">
                                            <IconTrash />
                                          </ActionIcon>
                                        </Table.Td>
                                        <Table.Td>
                                          <Checkbox
                                            readOnly={data?.isWarehouseSet || false}
                                            onChange={() => {
                                              setFieldValue(
                                                "variantSuppliers",
                                                values?.variantSuppliers?.map((item2: any, index2: number) => {
                                                  return {
                                                    ...item2,
                                                    isDefault: index === index2,
                                                  };
                                                }) ?? [],
                                              );
                                            }}
                                            checked={values?.variantSuppliers[index] ? values?.variantSuppliers[index]?.isDefault ?? false : false}
                                            label="Тийм"
                                          />
                                        </Table.Td>
                                        <Table.Td>
                                          <SelectFetchField
                                            readOnly={data?.isWarehouseSet || false}
                                            fetcher={async (filter: any) => {
                                              let res = await GoodsWarehouseApi.buyer({ ...filter });

                                              return res?.map((item: any) => {
                                                return {
                                                  label: `${item?.profileName ?? ""}`,
                                                  value: `${item?.id ?? ""}`,
                                                };
                                              });
                                            }}
                                            name={`variantSuppliers[${index}].buyerBusinessId`}
                                            onChange={() => {
                                              setFieldValue(`variantSuppliers[${index}].partnerId`, undefined);
                                              setFieldValue(`variantSuppliers[${index}].supplierBusinessId`, undefined);
                                            }}
                                          />
                                        </Table.Td>
                                        <Table.Td>
                                          <SelectFetchField
                                            readOnly={data?.isWarehouseSet ?? false}
                                            fetcher={async (filter: any) => {
                                              try {
                                                let res = [];
                                                if (values?.variantSuppliers[index]?.buyerBusinessId) {
                                                  res = await GoodsWarehouseApi.partner({
                                                    ...filter,
                                                    buyerBusinessId: values?.variantSuppliers[index]?.buyerBusinessId,
                                                  });
                                                }

                                                return [
                                                  ...res?.map((item: any) => {
                                                    return {
                                                      label: `${item?.businessName ?? ""}`,
                                                      value: `${item?.id ?? ""}`,
                                                      item: { ...item },
                                                    };
                                                  }),
                                                ];
                                              } catch (error) {
                                                return [];
                                              }
                                            }}
                                            name={`variantSuppliers[${index}].partnerId`}
                                            rest={{
                                              disabled: !values?.variantSuppliers[index]?.buyerBusinessId,
                                              placeholder: "Нийлүүлэгч Партнер",
                                            }}
                                            onChange={(e) => {
                                              setFieldValue(`variantSuppliers[${index}].supplierBusinessId`, undefined);
                                            }}
                                          />
                                        </Table.Td>
                                        <Table.Td>
                                          <SelectFetchField
                                            readOnly={data?.isWarehouseSet ?? false}
                                            getObj
                                            fetcher={async (filter: any) => {
                                              try {
                                                let res = [];

                                                if (values?.variantSuppliers[index]?.buyerBusinessId && values?.variantSuppliers[index]?.partnerId) {
                                                  res = await GoodsWarehouseApi.supplier({
                                                    ...filter,
                                                    buyerBusinessId: values?.variantSuppliers[index]?.buyerBusinessId,
                                                    partnerId: values?.variantSuppliers[index]?.partnerId,
                                                  });
                                                }

                                                return [
                                                  ...res?.map((item: any) => {
                                                    return {
                                                      label: `${item?.profileName ?? ""}`,
                                                      value: `${item?.id ?? ""}`,
                                                      disabled: values?.variantSuppliers?.some((item2: any) => item2?.supplierBusinessId === item?.id),
                                                      item,
                                                    };
                                                  }),
                                                ];
                                              } catch (error) {
                                                return [];
                                              }
                                            }}
                                            name={`variantSuppliers[${index}].supplierBusinessId`}
                                            rest={{
                                              disabled: !values?.variantSuppliers[index]?.buyerBusinessId || !values?.variantSuppliers[index]?.partnerId,
                                              placeholder: "Нийлүүлэгч бизнес",
                                            }}
                                            onChange={async (e: string | null, find: any) => {
                                              if (!e) return;
                                              if (find) {
                                                setFieldValue(`variantSuppliers[${index}].merchStaffId`, find.item.merchStaff.id ?? null);
                                              }
                                            }}
                                          />
                                        </Table.Td>

                                        <Table.Td>{renderManager(values?.variantSuppliers[index]?.supplierBusinessId ?? null)}</Table.Td>
                                        <Table.Td>
                                          <Checkbox
                                            readOnly={data?.isWarehouseSet || false}
                                            checked={values?.variantSuppliers[index]?.isDropshipping || false}
                                            label="Хийнэ"
                                            onChange={() => {
                                              if (!data?.isWarehouseSet) {
                                                setFieldValue(`variantSuppliers[${index}].isDropshipping`, !values?.variantSuppliers[index]?.isDropshipping);
                                                setFieldValue(`variantSuppliers[${index}].backorderable`, false);
                                              }
                                            }}
                                          />
                                        </Table.Td>
                                        <Table.Td>
                                          <Checkbox
                                            readOnly={data?.isWarehouseSet || false}
                                            checked={values?.variantSuppliers[index]?.backorderable || false}
                                            label="Хийнэ"
                                            onChange={() => {
                                              if (!data?.isWarehouseSet) {
                                                setFieldValue(`variantSuppliers[${index}].backorderable`, !values.variantSuppliers[index]?.backorderable);
                                                setFieldValue(`variantSuppliers[${index}].isDropshipping`, false);
                                              }
                                            }}
                                          />
                                        </Table.Td>
                                      </Table.Tr>
                                    );
                                  })
                                ) : (
                                  <Table.Tr>
                                    <Table.Td colSpan={8}>
                                      <Center py={"sm"}>
                                        <Text fz={"xs"} c="dimmed">
                                          Юу ч олдсонгүй.
                                        </Text>
                                      </Center>
                                    </Table.Td>
                                  </Table.Tr>
                                )}
                              </Table.Tbody>
                            </Table>
                          </Box>
                          <Alert title={"Санамж!"} color="red" hidden={!errors.variantSuppliers}>
                            {errors?.variantSuppliers}
                          </Alert>
                        </Grid.Col>
                      )}
                    </Grid>
                  </Box>
                </FormLayout>
              </Box>
            );
          }}
        </Form>
      </Paper>
    );
}

const stock = [
  {
    name: "minQuantity",
    label: "Захиалгын нөөцөд заавал байлгах үлдэгдэл",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: true,
  },
  {
    name: "noticeMinQuantity",
    label: "Анхааруулга өгөх минимум үлдэгдэл",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: true,
  },
  {
    name: "noticeMaxQuantity",
    label: "Анхааруулга өгөх максимум үлдэгдэл",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: true,
  },
  {
    name: "deactivateMinQuantity",
    label: "Жагсаалтад идэвхгүй болгох үлдэгдэл",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: true,
  },
  {
    name: "minOrderQuantity",
    label: "Минимум захиалгын тоо",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: false,
  },
  {
    name: "reOrderQuantityLevel",
    label: "Дахин захиалах үлдэгдэлийн түвшин",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: false,
  },
  {
    name: "reOrderQuantity",
    label: "Нөөц дахиан захиалах тоо хэмжээ",
    placeholder: "Үндсэн нэгжээр тоо ширхэг оруулна уу",
    required: false,
  },
];
